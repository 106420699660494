import React, { useEffect, useMemo, useState } from 'react';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { getIncompleteTechnologyNameWithRevision, getTechnologyNameWithRevision } from 'Features/technologies';

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog';
import { ErrorCodes } from 'Consts/Errors';
import HubMethods from 'Consts/HubMethods';
import { OrderStatus as OrderStatusType, OrderStatuses } from 'Consts/OrderStatuses';
import { useSignalRContext } from 'Context/SignalRContext';
import getErrorCode, { getErrorMessageForCode, getValidationErrors } from 'Helpers/ApiErrorCodeGetter';
import { useEditOrder } from 'Hooks/orders/useEditOrder';
import useOrderQuery from 'Hooks/orders/useOrderQuery';
import type { GetOrderDto, Option, OrderUpdatedMessage } from 'Types';

import OrderForm, { TechnologyPreview } from './OrderForm';
import { getOrderFormSchema, ORDER_MIN_AMOUNT, type OrderFormValues } from './OrderForm/validation';
import OrderStatus from './OrderStatus';
import { mapGetOrderDtoToFormValues, mapOrderFormValuesToPostDto } from './utils';

type Props = {
  open: boolean;
  orderId: number;
  onCancel: () => void;
  onSuccess: () => void;
};

const EditDialog = ({ open, onCancel, onSuccess, orderId }: Props) => {
  const { t } = useTranslation();
  const { hubConnection } = useSignalRContext();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>();

  const [minAmount, setMinAmount] = useState<number>(ORDER_MIN_AMOUNT);
  const [incompleteTechnologyIds, setIncompleteTechnologyIds] = useState<string[]>([]);
  const orderFormSchema = useMemo(
    () => getOrderFormSchema({ minAmount, incompleteTechnologyIds }),
    [minAmount, incompleteTechnologyIds]
  );

  const [loadingError, setLoadingError] = useState('');
  const [orderStatus, setOrderStatus] = useState<OrderStatusType | null>(null);
  const [technologyOption, setTechnologyOption] = useState<Option[]>([]);
  const [disableStartDate, setDisableStartDate] = useState(false);
  const methods = useForm<OrderFormValues>({
    mode: 'all',
    resolver: zodResolver(orderFormSchema),
    defaultValues: {
      amount: ORDER_MIN_AMOUNT,
      name: '',
      startDate: new Date(),
      endDate: new Date(),
      technologyId: undefined
    }
  });
  const {
    handleSubmit,
    setError: setFormError,
    reset,
    watch,
    formState: { isValid }
  } = methods;
  const orderName = watch('name');
  const { data: order, isLoading: isLoadingOrder } = useOrderQuery(orderId, {
    enabled: !!orderId,
    onSuccess: (data) => {
      if (data.status !== OrderStatuses.NotStarted) {
        setMinAmount(Math.max(data.produced, ORDER_MIN_AMOUNT));
        setDisableStartDate(true);
      }
      setOrderStatus(data.status);
      setTechnologyOption([
        {
          id: data.technologyId,
          name: data.isTechnologyComplete
            ? getTechnologyNameWithRevision(data.technologyName, data.technologyRevision)
            : getIncompleteTechnologyNameWithRevision(data.technologyName, data.technologyRevision)
        }
      ]);
      if (!data.isTechnologyComplete) {
        setIncompleteTechnologyIds([data.technologyId]);
      }
      reset(mapGetOrderDtoToFormValues(data));
    },
    onError: (error) => {
      if (getErrorCode(error) === ErrorCodes.ENTITY_NOT_FOUND) {
        setLoadingError(t('messages.selectedOrderHasBeenDeleted'));
      } else {
        setLoadingError(t('messages.sorryThereWasAProblemWithYourRequest'));
      }
      queryClient.invalidateQueries('orders');
    }
  });
  const { mutateAsync: submitOrder, isLoading: isSubmitting } = useEditOrder();
  const queryClient = useQueryClient();

  const handleSubmitError = (error: unknown) => {
    const validationErrors = getValidationErrors(error);

    validationErrors?.forEach(({ PropertyName, Code }) => {
      if (Code === ErrorCodes.NOT_UNIQUE) queryClient.invalidateQueries('orders');
      if (PropertyName) {
        setFormError(PropertyName as FieldPath<OrderFormValues>, {
          message: getErrorMessageForCode(Code),
          type: 'onChange'
        });
      } else {
        setSubmitErrorMessage(getErrorMessageForCode(Code));
      }
    });

    if (!validationErrors?.length) {
      const errorCode = getErrorCode(error);

      if (errorCode === ErrorCodes.ENTITY_NOT_FOUND) {
        setSubmitErrorMessage(t('messages.selectedOrderHasBeenDeleted'));
        queryClient.invalidateQueries('orders');
      } else {
        setSubmitErrorMessage(getErrorMessageForCode(errorCode));
      }
    }
  };

  const handleSubmitCallback = async (values: OrderFormValues) => {
    const data = mapOrderFormValuesToPostDto(values);
    await submitOrder(
      { id: orderId, ...data },
      {
        onSuccess,
        onError: (error) => handleSubmitError(error)
      }
    );
  };

  useEffect(() => {
    const handleOrderUpdated = (order: OrderUpdatedMessage) => {
      if (order.id === orderId) {
        setMinAmount(Math.max(order.units.produced, ORDER_MIN_AMOUNT));
      }
    };
    hubConnection?.on(HubMethods.OrderUpdated, handleOrderUpdated);

    return () => {
      hubConnection?.off(HubMethods.OrderUpdated, handleOrderUpdated);
    };
  }, [orderId, hubConnection]);

  if (isLoadingOrder) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <Dialog
          open={open}
          onClose={onCancel}
          PaperProps={{ sx: { minWidth: 'min(60rem, 90vw)', height: 'min(45rem, 85vh)' } }}
          disablePortal
        >
          <DialogTitle onClose={onCancel} disableTypography>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='h5' noWrap>
                {t('labels.editingAnOrder')}:{' '}
                <Typography component='span' fontWeight='bold' fontSize='inherit'>
                  {orderName}
                </Typography>
              </Typography>
              {orderStatus && <OrderStatus status={orderStatus} inLine />}
            </Stack>
          </DialogTitle>
          <DialogContent>
            {loadingError ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)'
                }}
              >
                <Typography color='error'>{loadingError}</Typography>
              </Box>
            ) : (
              <Stack spacing={2}>
                <Stack>
                  <OrderForm
                    disableTechnology
                    technologyOptions={technologyOption}
                    minAmount={minAmount}
                    disableStartDate={disableStartDate}
                    isTechnologyComplete={order?.isTechnologyComplete}
                    amountHelperText={t('messages.cannotSetValueBelowTheNumberOfAlreadyProduced')}
                  />
                  {order?.leadPredictedTime && (
                    <Typography variant='caption' color='text.secondary'>
                      {t('labels.estimatedWorkingTimeOnTheOrder')}: {order.leadPredictedTime}
                    </Typography>
                  )}
                </Stack>
                {order && <TechnologyComponents order={order} />}
              </Stack>
            )}
          </DialogContent>
          <DialogActions actionsVariant='center' errorMessage={submitErrorMessage}>
            <Button onClick={onCancel} variant='text'>
              {t('labels.cancel')}
            </Button>
            <Button type='submit' disabled={!isValid || isSubmitting || !!submitErrorMessage}>
              {t('labels.save')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

export const TechnologyComponents = ({ order }: { order: GetOrderDto }) => {
  const components = order.orderComponents.map((oc) => ({
    amount: oc.amount,
    name: oc.name,
    machines:
      oc.orderComponentMachines?.map(({ machineName, isActive, isDeleted }) => ({
        name: machineName,
        isActive,
        isDeleted
      })) ?? []
  }));

  return <TechnologyPreview components={components} />;
};

export default EditDialog;
