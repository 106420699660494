import { differenceInMinutes, intervalToDuration } from 'date-fns'

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const isDate = (date: Date) => new Date(date).toString() !== 'Invalid Date' && !Number.isNaN(new Date(date))

export const getCreationDate = (seconds: number): Date => {
  const creationTime = new Date()
  creationTime.setSeconds(creationTime.getSeconds() - seconds)

  return creationTime
}

export const getRepairTime = (duration: string) => {
  const pattern = /(\d+(:\d+)+)/

  if (!duration.match(pattern)) {
    return ''
  }

  const durationArray = duration.split(':')
  let hours = `${Math.trunc(Number(durationArray[0]))}`.padStart(2, '0')
  if (hours.startsWith('0')) {
    hours = hours.replace('0', '')
  }
  const minutes = durationArray[1].padStart(2, '0')

  if (hours === '0' && minutes === '00') {
    return '0h 01m'
  }
  const minutesLiteral = minutes !== '00' ? ` ${minutes}m` : ''

  return `${hours}h${minutesLiteral}`
}

export const isCurrentTimeInTheNextFifteenMinutes = (dateToCheck: Date) => {
  const currentTime = new Date(dateToCheck)
  currentTime.setMinutes(new Date().getMinutes())
  currentTime.setHours(new Date().getHours())
  const minutes = differenceInMinutes(dateToCheck, currentTime)

  return minutes > 0 && minutes <= 15
}

export const tokenDefaultExpirationValue = () => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  return tomorrow.getTime()
}

export const addHours = (date: Date, hours: number) => {
  if (!date || !isDate(date)) {
    return date
  }

  date.setTime(date.getTime() + hours * 60 * 60 * 1000)

  return date
}

export const getSoonToOverdueTime = (
  hours: number | undefined,
  minutes: number | undefined,
  seconds: number | undefined
) => {
  if (minutes !== undefined && hours !== undefined && seconds !== undefined) {
    if (minutes === 59) {
      return `${hours + 1}h 00m`
    }

    if (minutes === 0 && hours === 0 && seconds === 0) {
      return '0h 00m'
    }

    return `${hours}h ${minutes + 1}m`
  }

  return ''
}

export const secondsToHoursMinutesFormat = (durationInSeconds: number) => {
  if (durationInSeconds === undefined || durationInSeconds === null) {
    return '-'
  }

  if (durationInSeconds < 60) {
    return '1m'
  }

  const duration = intervalToDuration({ start: 0, end: durationInSeconds * 1000 })
  const days = duration.days ? ` ${duration.days}d` : ''
  const hours = duration.hours ? ` ${duration.hours}h` : ''
  const minutes = duration.minutes ? ` ${duration.minutes}m` : ''

  return `${days}${hours}${minutes}`.trim()
}

const EMPTY_TIME_SPAN_STRING = '--:--:--'
export function formatTimeSpan(timeSpanString?: string | null): string {
  if (!timeSpanString) return EMPTY_TIME_SPAN_STRING

  if (timeSpanString.includes('.')) {
    const parts = timeSpanString.split('.')
    const days = parseInt(parts[0], 10)
    const timeParts = parts[1].split(':')
    let hours = parseInt(timeParts[0], 10)
    const minutes = parseInt(timeParts[1], 10)
    const seconds = parseInt(timeParts[2], 10)

    hours += days * 24
    const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`

    return formattedDuration
  }

  return timeSpanString
}

export const convertSecondsToPivotGridWithChartDisplayFormat = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secondsVar = Math.floor(seconds % 60)
  const h = hours.toString().padStart(2, '0')
  const m = minutes.toString().padStart(2, '0')
  const s = secondsVar.toString().padStart(2, '0')

  return `${hours > 0 ? h + 'h:' : ''}${minutes > 0 ? m + 'm:' : ''}${s}s`
}

export const convertMinutesToMiliseconds = (minutes: number) => minutes * 60 * 1000
