import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { GetOrderDto } from 'Types';

const useOrderQuery = (id: number, options?: UseQueryOptions<GetOrderDto, unknown, GetOrderDto, ['order', number]>) =>
  useQuery(
    ['order', id],
    async () => {
      const { data } = await apiClient.getOrder(id);

      return data;
    },
    options
  );

export default useOrderQuery;
