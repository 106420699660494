import React from 'react';

import { formatTimePeriodToRangeString } from 'Helpers/Shifts';
import { TimePeriodOption } from 'Hooks/productionFilters/useProductionFilters';

import { PageHeaderTitle } from '..';

type Props = {
  title: string;
  timePeriod?: TimePeriodOption | null;
};

const ChartTitle = ({ title, timePeriod }: Props) => {
  return <PageHeaderTitle title={timePeriod ? `${title}: ${formatTimePeriodToRangeString(timePeriod)}` : title} />;
};

export default ChartTitle;
