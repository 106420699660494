import { FC, useEffect, useState } from 'react';

import DateRangeFilters from './DateRangeFilters';

type Props = {
  value: { from: Date; to: Date } | undefined;
  onChange: (data: { from: Date | null; to: Date | null }) => void;
};

const DateRangeFormFilters: FC<Props> = ({ value, onChange }) => {
  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>();

  const handleDateRangeChange = ([startDate, endDate]: [Date, Date]) => {
    setSelectedDateRange([startDate, endDate]);
    onChange({ from: startDate, to: endDate });
  };

  useEffect(() => {
    if (value) {
      setSelectedDateRange([value.from, value.to]);
    }
  }, [value]);

  return (
    <DateRangeFilters
      selectedDateRange={selectedDateRange ?? [new Date(), new Date()]}
      onDateRangeChange={handleDateRangeChange}
    />
  );
};

export default DateRangeFormFilters;
