import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import Config from 'config';
import { nanoid } from 'nanoid';

import { LocalStorageKeys } from 'Consts/LocalStorageKeys';
import { useMachineContext } from 'Context/MachineContext';
import { useSnackbar as useConnectedSnackbarContext } from 'Context/SnackbarsContext';
import { useUser as useUserContext } from 'Context/UserContext';
import { sleep } from 'Helpers/Time';
import ErrorTracker from 'Lib/error-tracker';
import paths from 'Routes/paths';
import { AnyType } from 'Types';

const HubServerMethods = {
  SubscribeToAppName: 'SubscribeToAppName',
  UnsubscribeFromAppName: 'UnsubscribeFromAppName',
  SubscribeToAuthorizeWorkstation: 'SubscribeToAuthorizeWorkstation',
  UnSubscribeFromAuthorizedWorkstation: 'UnSubscribeFromAuthorizedWorkstation',
  SubscribeToUser: 'SubscribeToUser',
  UnSubscribeFromUser: 'UnSubscribeFromUser'
};

const ErrorMessages = {
  AlreadyPaired: 'client is currently connected',
  InvocationCanceledDueToConnectionBeingClosed: 'Invocation canceled due to the underlying connection being closed'
};

const HubConnectionErrorType = 'Hub Connection Error';
const HubConnectionLogTitle = 'HubConnection';

interface SignalRContext {
  hubConnection?: HubConnection | null;
  connectedClientId: string | null;
}

const SignalRContext = createContext<SignalRContext | undefined>(undefined);

export function useSignalRContext() {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error('useSignalRContext must be within SignalRContextProvider');
  }

  return context;
}

const getHubUrl = (params: { clientId?: string; workstationId?: string } = {}) => {
  const HUB_URL = `${Config.HUB_DEFAULT_URL}/main`;
  const url = new URL(HUB_URL);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });

  return url.toString();
};

const buildHubConnection = (url: string) =>
  new HubConnectionBuilder()
    .withUrl(url)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => 5000
    })
    .build();

export const SignalRContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [connectedClientId, setConnectedClientId] = useState(localStorage.getItem(LocalStorageKeys.CLIENT_ID));
  const [hubConnection, setHubConnection] = useState<HubConnection | null>();
  const [hubConnectionState, setHubConnectionState] = useState<HubConnectionState>();
  const { machine, isAvailable } = useMachineContext();
  const machineId = machine?.id;
  const { user, selectedApp } = useUserContext();
  const { setConnectedSnackbarOpen, setDisconnectedSnackbarOpen } = useConnectedSnackbarContext();
  const history = useHistory();

  const handleInvokeError = (methodName: string) => (error: AnyType) => {
    if (error && !error.message.includes(ErrorMessages.InvocationCanceledDueToConnectionBeingClosed)) {
      ErrorTracker.captureMessage(`${HubConnectionLogTitle}: ${error.message}`, {
        level: 'error',
        tags: {
          type: HubConnectionErrorType
        },
        extra: {
          message: error.message,
          method: `invoke(${methodName})`
        }
      });

      if (error.message.includes(ErrorMessages.AlreadyPaired)) {
        history.push(paths.machineAlreadyPaired);
      }
    }
  };

  useEffect(() => {
    if (!connectedClientId) {
      const uniqueId = nanoid();

      setConnectedClientId(uniqueId);
      localStorage.setItem(LocalStorageKeys.CLIENT_ID, uniqueId);
    }
  }, [connectedClientId]);

  useEffect(() => {
    if (!connectedClientId || hubConnection) {
      return;
    }

    setHubConnection(buildHubConnection(getHubUrl()));
  }, [connectedClientId, hubConnection]);

  useEffect(() => {
    if (hubConnection) {
      hubConnection.onreconnecting(() => {
        setHubConnectionState(HubConnectionState.Reconnecting);
        setDisconnectedSnackbarOpen(true);
      });

      hubConnection.onreconnected(() => {
        setHubConnectionState(HubConnectionState.Connected);
        setDisconnectedSnackbarOpen(false);
        setConnectedSnackbarOpen(true);
      });

      hubConnection.onclose((error: Error | undefined) => {
        setHubConnectionState(HubConnectionState.Disconnected);
        if (error?.message.includes(ErrorMessages.AlreadyPaired)) {
          ErrorTracker.captureMessage(`${HubConnectionLogTitle}: ${ErrorMessages.AlreadyPaired}`, {
            level: 'info',
            tags: {
              type: HubConnectionErrorType
            },
            extra: {
              message: error.message,
              workstation: machine
            }
          });
          history.push(paths.machineAlreadyPaired);
        } else if (error) {
          ErrorTracker.captureException(`${HubConnectionLogTitle}: ${error.message}`, {
            level: 'error',
            tags: {
              type: HubConnectionErrorType
            },
            extra: {
              workstation: machine
            }
          });
        }
      });

      const startHubConnection = () => {
        if (hubConnection.state === HubConnectionState.Disconnected) {
          hubConnection
            .start()
            .then(() => {
              setHubConnectionState(HubConnectionState.Connected);
              setDisconnectedSnackbarOpen(false);
            })
            .catch((error) => {
              setDisconnectedSnackbarOpen(true);
              if (error) {
                ErrorTracker.captureException(`${HubConnectionLogTitle}: ${error.message}`, {
                  level: 'error',
                  tags: {
                    type: HubConnectionErrorType
                  },
                  extra: {
                    workstation: machine
                  }
                });
              }
              sleep(5000).then(() => startHubConnection());
            });
        }
      };

      startHubConnection();
    }
  }, [hubConnection]);

  useEffect(() => {
    const canInvokeMethods = !!hubConnection && hubConnectionState === HubConnectionState.Connected;

    const shouldSubscribeToUser = canInvokeMethods && user;
    if (shouldSubscribeToUser) {
      hubConnection
        .invoke(HubServerMethods.SubscribeToUser, user.rfid)
        .catch(handleInvokeError(HubServerMethods.SubscribeToUser));
    }

    return () => {
      if (shouldSubscribeToUser) {
        hubConnection
          .invoke(HubServerMethods.UnSubscribeFromUser, user.rfid)
          .catch(handleInvokeError(HubServerMethods.UnSubscribeFromUser));
      }
    };
  }, [hubConnection, hubConnectionState, user]);

  useEffect(() => {
    const canInvokeMethods = !!hubConnection && hubConnectionState === HubConnectionState.Connected;

    const shouldSubscribeToAuthorizeWorkstation =
      canInvokeMethods && isAvailable && !user && !!machineId && !!connectedClientId;
    if (shouldSubscribeToAuthorizeWorkstation) {
      hubConnection
        .invoke(HubServerMethods.SubscribeToAuthorizeWorkstation, machineId, connectedClientId)
        .catch(handleInvokeError(HubServerMethods.SubscribeToAuthorizeWorkstation));
    }

    return () => {
      if (shouldSubscribeToAuthorizeWorkstation) {
        hubConnection
          .invoke(HubServerMethods.UnSubscribeFromAuthorizedWorkstation, machineId)
          .catch(handleInvokeError(HubServerMethods.UnSubscribeFromAuthorizedWorkstation));
      }
    };
  }, [hubConnection, hubConnectionState, user, selectedApp, machineId, isAvailable, connectedClientId]);

  useEffect(() => {
    const canInvokeMethods = !!hubConnection && hubConnectionState === HubConnectionState.Connected;

    const shouldSubscribeToAppName = canInvokeMethods && !!selectedApp && !!user;
    if (shouldSubscribeToAppName) {
      hubConnection
        .invoke(HubServerMethods.SubscribeToAppName, selectedApp, machineId, user.rfid)
        .catch(handleInvokeError(HubServerMethods.SubscribeToAppName));
    }

    return () => {
      if (shouldSubscribeToAppName) {
        hubConnection
          .invoke(HubServerMethods.UnsubscribeFromAppName, selectedApp, machineId, user.rfid)
          .catch(handleInvokeError(HubServerMethods.UnsubscribeFromAppName));
      }
    };
  }, [hubConnection, hubConnectionState, selectedApp, user, machineId]);

  const value: SignalRContext = {
    hubConnection,
    connectedClientId
  };

  return <SignalRContext.Provider value={value}>{children}</SignalRContext.Provider>;
};

export const SignalRContextProviderMock: React.FC<{ value?: SignalRContext }> = ({ children, value }) => {
  const defaultValue: SignalRContext = {
    hubConnection: null,
    connectedClientId: 'mockConnectedClientId'
  };

  return <SignalRContext.Provider value={value ?? defaultValue}>{children}</SignalRContext.Provider>;
};
