import React from 'react';
import { Box } from '@mui/material';

interface PageContainerProps {
  height?: string;
}

const PageContainer: React.FC<PageContainerProps> = ({ children, height }) => (
  <Box mx={2} height={height ?? '100vh'} pb={4}>
    {children}
  </Box>
);

export default PageContainer;
