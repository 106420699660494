import React, { createContext, useContext, useMemo, useState } from 'react';

type DialogContext = {
  multipleUnitsDialogOpen: boolean;
  setMultipleUnitsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  failureStatusSelectOpen: boolean;
  setFailureStatusSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cardRemovedOpen: boolean;
  setCardRemovedOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removeCardDialogOpen: boolean;
  setRemoveCardDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  failureUnitsDialogOpen: boolean;
  setFailureUnitsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeAll: () => void;
  addRepairTaskDialogOpen: boolean;
  setAddRepairTaskDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addMaintenanceTaskDialogOpen: boolean;
  setAddMaintenanceTaskDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editCalendarTaskDialogOpen: boolean;
  setEditCalendarTaskDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accidentReportedConfirmationDialogOpen: boolean;
  setAccidentReportedConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productionStartedWithoutReportingReasonDialogOpen: boolean;
  setProductionStartedWithoutReportingReasonDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productionStartedWithoutReportingReasonDialogConfirmed: boolean;
  setProductionStartedWithoutReportingReasonDialogConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  potentialNOKReportDialogOpen: boolean;
  setPotentialNOKReportDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  networkErrorDialogOpen: boolean;
  setNetworkDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addMultiOperatorDialogOpen: boolean;
  setAddMultiOperatorDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  replaceMultiOperatorDialogOpen: boolean;
  setReplaceMultiOperatorDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  enforceRetoolFromOrderDialogOpen: boolean;
  setEnforceRetoolFromOrderDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  suspendRetoolDialogOpen: boolean;
  setSuspendRetoolDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  potentialFailureDialogOpen: boolean;
  setPotentialFailureDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  produceManualBunchDialogOpen: boolean;
  setProduceManualBunchDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DialogsContext = createContext<DialogContext | undefined>(undefined);

export const useDialogsContext = () => {
  const context = useContext(DialogsContext);
  if (context === undefined) {
    throw new Error('useDialogsContext must be within MachineContextProvider');
  }

  return context;
};

export const DialogsProvider: React.FC = ({ children }) => {
  const [multipleUnitsDialogOpen, setMultipleUnitsDialogOpen] = useState(false);
  const [failureStatusSelectOpen, setFailureStatusSelectOpen] = useState(false);
  const [cardRemovedOpen, setCardRemovedOpen] = useState(false);
  const [removeCardDialogOpen, setRemoveCardDialogOpen] = useState(false);
  const [failureUnitsDialogOpen, setFailureUnitsDialogOpen] = useState(false);
  const [addRepairTaskDialogOpen, setAddRepairTaskDialogOpen] = useState(false);
  const [addMaintenanceTaskDialogOpen, setAddMaintenanceTaskDialogOpen] = useState(false);
  const [editCalendarTaskDialogOpen, setEditCalendarTaskDialogOpen] = useState(false);
  const [networkErrorDialogOpen, setNetworkDialogOpen] = useState(false);
  const [accidentReportedConfirmationDialogOpen, setAccidentReportedConfirmationDialogOpen] = useState(false);
  const [productionStartedWithoutReportingReasonDialogOpen, setProductionStartedWithoutReportingReasonDialogOpen] =
    useState(false);
  const [
    productionStartedWithoutReportingReasonDialogConfirmed,
    setProductionStartedWithoutReportingReasonDialogConfirmed
  ] = useState(false);
  const [potentialNOKReportDialogOpen, setPotentialNOKReportDialogOpen] = useState(false);
  const [addMultiOperatorDialogOpen, setAddMultiOperatorDialogOpen] = useState(false);
  const [replaceMultiOperatorDialogOpen, setReplaceMultiOperatorDialogOpen] = useState(false);
  const [enforceRetoolFromOrderDialogOpen, setEnforceRetoolFromOrderDialogOpen] = useState(false);
  const [suspendRetoolDialogOpen, setSuspendRetoolDialogOpen] = useState(false);
  const [potentialFailureDialogOpen, setPotentialFailureDialogOpen] = useState(false);
  const [produceManualBunchDialogOpen, setProduceManualBunchDialogOpen] = useState(false);

  const closeAll = () => {
    setMultipleUnitsDialogOpen(false);
    setCardRemovedOpen(false);
    setRemoveCardDialogOpen(false);
    setFailureUnitsDialogOpen(false);
    setAddRepairTaskDialogOpen(false);
    setAddMaintenanceTaskDialogOpen(false);
    setAddMaintenanceTaskDialogOpen(false);
    setAccidentReportedConfirmationDialogOpen(false);
    setProductionStartedWithoutReportingReasonDialogOpen(false);
    setProductionStartedWithoutReportingReasonDialogConfirmed(false);
    setPotentialNOKReportDialogOpen(false);
    setAddMultiOperatorDialogOpen(false);
    setReplaceMultiOperatorDialogOpen(false);
    setEnforceRetoolFromOrderDialogOpen(false);
    setSuspendRetoolDialogOpen(false);
    setPotentialFailureDialogOpen(false);
  };

  const value = useMemo(
    () => ({
      multipleUnitsDialogOpen,
      setMultipleUnitsDialogOpen,
      failureStatusSelectOpen,
      setFailureStatusSelectOpen,
      cardRemovedOpen,
      setCardRemovedOpen,
      removeCardDialogOpen,
      setRemoveCardDialogOpen,
      failureUnitsDialogOpen,
      setFailureUnitsDialogOpen,
      closeAll,
      addRepairTaskDialogOpen,
      setAddRepairTaskDialogOpen,
      addMaintenanceTaskDialogOpen,
      setAddMaintenanceTaskDialogOpen,
      editCalendarTaskDialogOpen,
      setEditCalendarTaskDialogOpen,
      accidentReportedConfirmationDialogOpen,
      setAccidentReportedConfirmationDialogOpen,
      productionStartedWithoutReportingReasonDialogOpen,
      setProductionStartedWithoutReportingReasonDialogOpen,
      productionStartedWithoutReportingReasonDialogConfirmed,
      setProductionStartedWithoutReportingReasonDialogConfirmed,
      potentialNOKReportDialogOpen,
      setPotentialNOKReportDialogOpen,
      networkErrorDialogOpen,
      setNetworkDialogOpen,
      addMultiOperatorDialogOpen,
      setAddMultiOperatorDialogOpen,
      replaceMultiOperatorDialogOpen,
      setReplaceMultiOperatorDialogOpen,
      enforceRetoolFromOrderDialogOpen,
      setEnforceRetoolFromOrderDialogOpen,
      suspendRetoolDialogOpen,
      setSuspendRetoolDialogOpen,
      potentialFailureDialogOpen,
      setPotentialFailureDialogOpen,
      produceManualBunchDialogOpen,
      setProduceManualBunchDialogOpen
    }),
    [
      multipleUnitsDialogOpen,
      failureStatusSelectOpen,
      cardRemovedOpen,
      removeCardDialogOpen,
      failureUnitsDialogOpen,
      addRepairTaskDialogOpen,
      networkErrorDialogOpen,
      accidentReportedConfirmationDialogOpen,
      productionStartedWithoutReportingReasonDialogOpen,
      productionStartedWithoutReportingReasonDialogConfirmed,
      potentialNOKReportDialogOpen,
      addMultiOperatorDialogOpen,
      replaceMultiOperatorDialogOpen,
      addMaintenanceTaskDialogOpen,
      editCalendarTaskDialogOpen,
      enforceRetoolFromOrderDialogOpen,
      suspendRetoolDialogOpen,
      potentialFailureDialogOpen,
      produceManualBunchDialogOpen
    ]
  );

  return <DialogsContext.Provider value={value}>{children}</DialogsContext.Provider>;
};
