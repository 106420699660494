import React from 'react';

import SelectedAppHandlers from './SelectedAppHandlers';
import SharedHandlers from './SharedHandlers';

const GlobalStateHandlers = () => {
  return (
    <>
      <SharedHandlers />
      <SelectedAppHandlers />
    </>
  );
};

export default GlobalStateHandlers;
