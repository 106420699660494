import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { TechnologyDto } from 'Types';

const useTechnology = (
  id: string,
  options?: UseQueryOptions<TechnologyDto, unknown, TechnologyDto, ['technologist-technology', string]>
) =>
  useQuery(
    ['technologist-technology', id],
    async () => {
      const { data } = await apiClient.getTechnology(id);

      return data;
    },
    options
  );

export default useTechnology;
